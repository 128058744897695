import React from "react";
import tw from "twin.macro";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import DownloadApp from '../components/cta/DownloadApp.js';

export default () => {
	const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
	const HighlightedText = tw.span`text-primary-500`;
	const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

	return (
		<StyledDiv>
			<Hero roundedHeaderButton={true}/>
			<Features
				subheading={<Subheading>Что мы можем</Subheading>}
				heading={
					<>
						Всё это доступно в нашем мобильном приложении
					</>
				}
			/>
			<Pricing
				subheading={<Subheading>Тарифы</Subheading>}
				heading={
					<>
						Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
					</>
				}
				plans={[
					{
						name: 'Бригада',
						durationPrices: [0, 0],
						duration: 'навсегда',
						mainFeature: 'для бригадиров и прорабов',
						features: [
							'1 пользователь',
							'до 5 работников',
							'журнал смен и сдельные работ',
							'журнал выплат и начислений',
							'многое другое',
						]
					},
					{
						name: 'Компания',
						durationPrices: [100, 1000],
						duration: 'в месяц',
						mainFeature: 'для предприятий',
						features: [
							'любое кол-во пользователей',
							'любое кол-во работников',
							'все возможности приложения',
						],
					},
				]}
			/>

			<DownloadApp
				text={<>Попробуйте сами и решите, подходит ли наше решение именно вам</>}
			/>

			<Footer/>
		</StyledDiv>
	);
}
