import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/design-illustration-2.svg";
import Image from '../../4786.svg';
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";
import appleIconImageSrc from '../../images/apple-icon.png';
import googlePlayIconImageSrc from '../../images/google-play-icon.png';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`

const Link = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-gray-100 hocus:bg-gray-300 text-gray-900 hocus:text-gray-900 shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
  img {
    ${tw`inline-block h-8 mr-3`}
  }
  span {
    ${tw`leading-none inline-block`}
  }
`;

export default ({roundedHeaderButton}) => {
	const link1Text = "App Store";
	const link1Url = "https://oktocloud.page.link/ios";
	const link1IconSrc = appleIconImageSrc;
	const link2Text = "Google Play";
	const link2Url = "https://oktocloud.page.link/play";
	const link2IconSrc = googlePlayIconImageSrc;

	return (
		<>
			<Header roundedHeaderButton={roundedHeaderButton}/>
			<Container>
				<TwoColumn>
					<LeftColumn>
						<Heading>
							Учёт смен и работ для вашей команды
						</Heading>
						<Paragraph>
							Мобильное приложение Okto поможет навести порядок в расчёте заработной платы за выполненные
							сдельные работы и отработанные смены.
						</Paragraph>
						<LinksContainer>
							<Link href={link1Url} target="_blank">
								<img src={link1IconSrc} alt=""/>
								<span>{link1Text}</span>
							</Link>
							<Link href={link2Url} target="_blank">
								<img src={link2IconSrc} alt=""/>
								<span>{link2Text}</span>
							</Link>
						</LinksContainer>
					</LeftColumn>
					<RightColumn>
						<IllustrationContainer>
							<img tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={Image}
								 alt="Design Illustration"/>
						</IllustrationContainer>
					</RightColumn>
				</TwoColumn>
				<DecoratorBlob1/>
			</Container>
		</>
	);
};
