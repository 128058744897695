import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Okto from './demos/Okto.js';

export default function App() {
	return (
		<Router>
			<Switch>
				<Route path="/">
					<Okto/>
				</Route>
			</Switch>
		</Router>
	);
}
